@media only screen and (max-width: 600px) {
    .Footer-copyright {
    background-color: #1f1f1f;
    color: #ffffff;
    height: auto;
    width: 100%;
    align-items: center;
    justify-content:center;
    display: flex;
  }

  .Footer-copyright h1 {
    font-size: 17px;
    padding: 15px 0px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .Footer-copyright {
    background-color: #1f1f1f;
    color: #ffffff;
    height: auto;
    width: 100%;
    align-items: center;
    justify-content:center;
    display: flex;
  }

  .Footer-copyright h1 {
    font-size: 17px;
    padding: 15px 0px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Footer-copyright {
    background-color: #1f1f1f;
    color: #ffffff;
    height: auto;
    width: 100%;
    align-items: center;
    justify-content:center;
    display: flex;
  }

  .Footer-copyright h1 {
    font-size: 17px;
    padding: 15px 0px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}

