@mixin btn() {
  padding: 0.3rem 0.5rem;
  border-radius: 20px;

  &:hover {
    box-shadow: var(--box-shadow);
  }
}

@mixin lnk() {
  color: var(--text-link-color);
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}

@mixin card() {
  padding: 1rem;
  border-radius: 10px;
  background: var(--light-color);
  box-shadow: var(--box-shadow);
}
