@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;

}

body {
    background: #090A29;
    
  
}

body::-webkit-scrollbar {
  height: 10px;
  background-color: #fff;
}

body::-webkit-scrollbar-thumb {
  background: #300158;
  border-top: 50px white solid;
  background-clip: padding-box;


}




body, html {
      height: 100%;
      
}

// reset

button {
  border: unset;
  background: unset;
  font: unset;
  color: unset;
  cursor: pointer;
}

a {
  color: unset;
  text-decoration: unset;
}

input,
textarea {
  border: unset;
  color: unset;
  background: unset;
  font: unset;
}
