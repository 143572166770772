/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .navbarwrapper {
    width: 100%;
    position: fixed;
    z-index: 3;
  }

  .navbar {
    height: 70px;
    background: #15213B;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .brandname1 {
    display: none;
  }

  .brandname2 {
    display: block;
    width: 150px;
    margin-left: 10px;
  }

  .links {
    display: none;
  }

  .togglediv {
    padding-left: 10px;
    min-width: 30px;
  }

  .toggle {
    width: 2.8rem;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    padding: 0px 10px;
  }

  .getbutton {
    padding: 0px 10px 0 5px;
  }

  .getbutton button {
    background: #6400E3;
    border-radius: 5px;
    padding: 5px;
    height: 35px;
    color: rgb(255, 255, 255);
    min-width: 80px;
  }

  @keyframes example {
    from {width: 0%;}
    to {width: 80%;}
  }

  .mobile-links {
    z-index: -999999;
    display: block;
    background: #0a1224;
    margin-left: 0;
    margin-right: auto;
    height: 100vh;
    margin-top: -53px;
    width: 80%;
    animation-name: example;
    animation-duration: 0.3s;
  }

  .mobile-padding {
    padding: 50px 10px 20px 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .mobile-hr {
    border: 1px solid #a8a8a84b;
  }

  #navlinks-mobile {
    color: #ffffff;
    font-size: 20px;
  }

  #navlinks-mobile p {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .regmobile-div {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: flex-start;
  }

  .btn-register-mobile {
    color: #ffffff;
    background-color: #6400E3;
    border-radius: 5px;
    padding: 5px 25px;
    font-size: 20px;
  }

  .btn-reg-mobile {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .btn-logout {
    background: red;
    padding: 5px 10px;
    border-radius: 15px;
    margin-right: 15px;
    color: #ffffff;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .navbarwrapper {
    width: 100%;
    position: fixed;
    z-index: 3;
  }

  .navbar {
    height: 70px;
    background: #15213B;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .brandname1 {
    display: none;
  }

  .brandname2 {
    display: block;
    width: 150px;
  }

  .links {
    display: none;
  }

  .togglediv {
    padding-left: 10px;
    min-width: 30px;
  }

  .toggle {
    width: 2.8rem;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    padding: 0px 10px;
  }

  .getbutton {
    padding: 0px 10px 0 5px;
  }

  .getbutton button {
    background: #6400E3;
    border-radius: 5px;
    padding: 5px;
    height: 35px;
    color: rgb(255, 255, 255);
    min-width: 80px;
  }

  @keyframes example {
    from {width: 0%;}
    to {width: 80%;}
  }

  .mobile-links {
    z-index: -999999;
    display: block;
    background: #0a1224;
    margin-left: 0;
    margin-right: auto;
    height: 100vh;
    margin-top: -53px;
    width: 80%;
    animation-name: example;
    animation-duration: 0.3s;
  }

  .mobile-padding {
    padding: 50px 10px 20px 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .mobile-hr {
    border: 1px solid #a8a8a84b;
  }

  #navlinks-mobile {
    color: #ffffff;
    font-size: 20px;
  }

  #navlinks-mobile p {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .regmobile-div {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: flex-start;
  }

  .btn-register-mobile {
    color: #ffffff;
    background-color: #6400E3;
    border-radius: 5px;
    padding: 5px 25px;
    font-size: 20px;
  }

  .btn-reg-mobile {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .navbarwrapper {
    width: 100%;
    position: fixed;
    z-index: 3;
  }
  .navbar {
    height: 70px;
    background: #15213B;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .brandname1 {
    display: block;
    position: absolute;
    margin: 20px 0px 0px 50px;
    width: 150px;
  }

  .brandname2 {
    display: none;
  }

  .links {
    display: flex;
    float: right;
    align-items: center;
    margin-right: 50px;
    overflow-x: visible;
  }

  .btn-register {
    background: #6400E3;
    border-radius: 5px;
    width: 120px;
    text-align: center;
  }

  .btn-register:hover {
    background: #fafafa;
  }

  .btn-register p {
    padding: 5px 10px;
    color: rgb(255, 255, 255);
  }

  .btn-register p:hover {
    padding: 5px 10px;
    color: #7000c5;
  }

  #navlinks {
    color: rgb(255, 255, 255);
    padding: 0px 15px;
  }

  .titlediv {
    padding: 0px 450px 0px 0px;
    padding-top: 10px;
  }

  #navlinks:hover {
    color: #7000c5;
  }

  .getbutton button,
  .getbutton,
  .toggle,
  .togglediv {
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .navbarwrapper {
    width: 100%;
    position: fixed;
    z-index: 3;
  }
  .navbar {
    height: 70px;
    background: #15213B;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .brandname1 {
    display: block;
    position: absolute;
    margin: 15px 0px 0px 50px;
    width: 150px;
  }

  .brandname2 {
    display: none;
  }

  .links {
    display: flex;
    float: right;
    align-items: center;
    margin-right: 50px;
    overflow-x: visible;
  }

  .btn-register {
    background: #6400E3;
    border-radius: 5px;
    width: 120px;
    text-align: center;
  }

  .btn-register:hover {
    background: #ffffff;
  }

  .btn-register p {
    padding: 5px 10px;
    color: rgb(255, 255, 255);
  }

  .btn-register p:hover {
    padding: 5px 10px;
    color: #7000c5;
  }

  #navlinks {
    color: rgb(255, 255, 255);
    padding: 0px 15px;
  }

  .titlediv {
    padding: 0px 450px 0px 0px;
    padding-top: 10px;
  }

  #navlinks:hover {
    color: #7000c5;
  }

  .getbutton button,
  .getbutton,
  .toggle,
  .togglediv {
    display: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .navbarwrapper {
    width: 100%;
    position: fixed;
    z-index: 3;
  }

  
  .navbar {
    height: 60px;
    background: #15213B;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    
  }

  .brandname1 {
    display: block;
    position: absolute;
    margin: 15px 0px 0px 50px;
    width: 150px;
    
  }

  .brandname2 {
    display: none;
  }

  .links {
    display: flex;
    float: right;
    align-items: center;
    margin-right: 50px;
    overflow-x: visible;
  }

  .btn-register {
    background: #6400E3;
    border-radius: 5px;
    width: 120px;
    text-align: center;
  }

  .btn-register:hover {
    background: #ffffff;
  }

  .btn-register p {
    padding: 5px 10px;
    color: rgb(255, 255, 255);
  }

  .btn-register p:hover {
    padding: 5px 10px;
    color: #7000c5;
  }

  #navlinks {
    color: rgb(255, 255, 255);
    padding: 0px 15px;
    font-size: 15px;
  }

  .titlediv {
    padding: 0px 0px 0px 0px;
    padding-top: 10px;
  }

  #navlinks:hover {
    color: #7000c5;
  }

  .getbutton button,
  .getbutton,
  .toggle,
  .togglediv {
    display: none;
  }

  .btn-logout {
    background: red;
    padding: 5px 10px;
    border-radius: 15px;
    margin-right: 15px;
    color: #ffffff;
  }
}
