@import "../../style/mixins.scss";

.error-message {
 
  padding: 0.3rem;
  border: 1px solid var(--danger-color);
  border-radius: 5px;
  background: var(--danger-light-color);
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: red
  }

  button {
    @include btn();
    padding: 0.1rem 0.4rem;
    background: rgb(173, 4, 4);
    color: #ffffff;
  }
}
