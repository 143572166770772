.snippet-editor-div{
  position: absolute;
  width: 100%;
  height: 100vh;
  

}


.snippet-editor{
  margin-left: auto;
  margin-right: auto;
  background: #08020F;
  box-shadow: 0px 0px 30px 1px #26003D;
  width: 450px;
  margin-top: 25vh;

  
  
  
}

.Snippeteditor-padding{
  padding: 50px;
}





#editor-title,
#editor-description,
#editor-code{
  border: unset;
  background: #0A1121;
  border-radius: 5px;
  height: 35px;
  width: 100%;
  color: #ffffff;
  margin-bottom: 10px;
  border-color: unset;

}

#editor-title:focus,
#editor-description:focus,
#editor-code:focus{
  border: 3px solid red;
  border-color: #08020F;
}

.editorbtn-div{
  display: flex;
  justify-content: space-between;
  
}

.btn-save,
.btn-cancel{
    border-radius: 5px;
    padding: 5px 10px;
}

.btn-save{
  background: #004200;
}

.btn-cancel{
  background: #740000;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .snippet-editor-div{
    position: absolute;
    width: 100%;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    
  
  }
  
  
  .snippet-editor{
    margin-left: auto;
    margin-right: auto;
    background: #08020F;
    box-shadow: 0px 0px 30px 1px #26003D;
    width: 100%;
    margin-top: 25vh;
  
    
    
    
  }
  
  .Snippeteditor-padding{
    padding: 50px;
  }
  
  
  
  
  
  #editor-title,
  #editor-description,
  #editor-code{
    border: unset;
    background: #0A1121;
    border-radius: 5px;
    height: 35px;
    width: 100%;
    color: #ffffff;
    margin-bottom: 10px;
    border-color: unset;
  
  }
  
  #editor-title:focus,
  #editor-description:focus,
  #editor-code:focus{
    border: 3px solid red;
    border-color: #08020F;
  }
  
  .editorbtn-div{
    display: flex;
    justify-content: space-between;
    
  }
  
  .btn-save,
  .btn-cancel{
      border-radius: 5px;
      padding: 5px 10px;
  }
  
  .btn-save{
    background: #004200;
  }
  
  .btn-cancel{
    background: #740000;
  }
  
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}