.snippet{
  margin-left: 15px;
  margin-right: 15px;
  height: 200px;
  min-width: 200px;
  
}

.title{
  font-size: 90%;
}
.snippet-padding{
  width: 100%;
  background: #0A1121;
  padding: 10px;
  height: auto;
  text-align: center;
  color: #ffffff;
  border-radius: 15px;
  
}

.details-div{
  margin-top: 50px;
}

.snippet-buttons{
  display: flex;
  justify-content: space-around;
}

.btn-edit,
.btn-delete{
    padding: 5px;
    border-radius: 10px;
    width: 80px;
}

.btn-edit{
  background: #300158;
}

.btn-delete{
  background-color: #4d0000;

}