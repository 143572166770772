/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .loginwrapper{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #0a1224;
        height: 100vh;
    }
    
    .auth-padding{
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
        background: #ffffff;
        border-radius: 5px;
        padding: 25px 30px;
        height: 350px;
        width: 100%;
        text-align: center;
    }
    
    .loginlabel{
        color: #0a1224;
        padding-bottom: 20px;
    }
    
    #login-form-email,
    #login-form-password{
        background: #ececec;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        color: rgb(0, 0, 0); 
    }
    
    #login-form-email{
        margin-bottom: 15px;
    }
    
    #login-form-email:focus{
        background: #cfcfcf;
        border: 2px solid #ffffff;
        color: #000000;
    }
    
    #login-form-password:focus{
        background: #cfcfcf;
        border: 2px solid #ffffff;
        color: #000000;
    }
    
    .regdiv{
        text-align: left;
    }
    
    .reglink{
        color: #0041da;
    }
    
    .text{
        font-size: 2.8vw;
    }
    
    .loginbutton{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        align-items: center;
        padding-top: 10px;
    }
    
    .login{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #640870;
        border-radius: 5px;
        height: 40px;
        color: #ffffff;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .loginwrapper{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #0a1224;
        height: 100vh;
    }
    
    .auth-padding{
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
        background: #ffffff;
        border-radius: 5px;
        padding: 25px 30px;
        height: 350px;
        width: 80%;
        text-align: center;
    }
    
    .loginlabel{
        color: #0a1224;
        padding-bottom: 20px;
    }
    
    #login-form-email,
    #login-form-password{
        background: #ececec;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        color: rgb(0, 0, 0); 
    }
    
    #login-form-email{
        margin-bottom: 15px;
    }

    #login-form-email:focus{
        background: #cfcfcf;
        border: 2px solid #ffffff;
        color: #000000;
    }
    
    #login-form-password:focus{
        background: #cfcfcf;
        border: 2px solid #ffffff;
        color: #000000;
    }
    
    .regdiv{
        text-align: left;
    }
    
    .reglink{
        color: #0041da;
    }
    
    .text{
        font-size: 2.5vw;
    }
    
    .loginbutton{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        align-items: center;
        padding-top: 10px;
    }
    
    .login{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #640870;
        border-radius: 5px;
        height: 40px;
        color: #ffffff;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .loginwrapper{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #0a1224;
        height: 100vh;
    }
    
    .auth-padding{
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
        background: #ffffff;
        border-radius: 5px;
        padding: 25px 30px;
        height: 350px;
        width: 60%;
        text-align: center;
    }
    
    .loginlabel{
        color: #0a1224;
        padding-bottom: 20px;
    }
    
    #login-form-email,
    #login-form-password{
        background: #ececec;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        color: rgb(0, 0, 0); 
    }
    
    #login-form-email{
        margin-bottom: 15px;
    }
    
    #login-form-email:focus{
        background: #cfcfcf;
        border: 2px solid #ffffff;
        color: #000000;
    }
    
    #login-form-password:focus{
        background: #cfcfcf;
        border: 2px solid #ffffff;
        color: #000000;
    }
    
    .regdiv{
        text-align: left;
    }
    
    .reglink{
        color: #0041da;
    }
    
    .text{
        font-size: 1.5vw;
    }
    
    .loginbutton{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        align-items: center;
        padding-top: 10px;
    }
    
    .login{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #640870;
        border-radius: 5px;
        height: 40px;
        color: #ffffff;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .loginwrapper{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #0a1224;
        height: 100vh;
    }
    
    .auth-padding{
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
        background: #ffffff;
        border-radius: 5px;
        padding: 25px 30px;
        height: 350px;
        width: 50%;
        text-align: center;
    }
    
    .loginlabel{
        color: #0a1224;
        padding-bottom: 20px;
    }
    
    #login-form-email,
    #login-form-password{
        background: #ececec;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        color: rgb(0, 0, 0); 
    }
    
    #login-form-email{
        margin-bottom: 15px;
    }
    
    
    .regdiv{
        text-align: left;
    }
    
    .reglink{
        color: #0041da;
    }
    
    .text{
        font-size: 1.3vw;
    }
    
    .loginbutton{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        align-items: center;
        padding-top: 10px;
    }
    
    .login{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #640870;
        border-radius: 5px;
        height: 40px;
        color: #ffffff;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .loginwrapper{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #0a1224;
        height: 100vh;
    }
    
    .auth-padding{
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
        background: #ffffff;
        border-radius: 5px;
        padding: 30px 60px;
        height: 350px;
        width: 30%;
        text-align: center;
    }
    
    .loginlabel{
        color: #0a1224;
        padding-bottom: 30px;
    }
    
    #login-form-email,
    #login-form-password{
        background: #ececec;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        color: rgb(0, 0, 0); 
    }
    
    #login-form-email{
        margin-bottom: 15px;
    }
    
    
    
    .regdiv{
        text-align: left;
    }
    
    .reglink{
        color: #8891a5;
    }
    
    .text{
        font-size: .8vw;
    }
    
    .loginbutton{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        align-items: center;
        padding-top: 10px;
    }
    
    .login{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #640870;
        border-radius: 5px;
        height: 40px;
        color: #ffffff;
    }
}


