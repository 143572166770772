.policy_wrapper{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    
}

.policy_div{
    margin-top: 80px;
    margin-bottom: 50px;
    background: #ffffff;
    border-radius: 5px;
    width: 50%;
    
}

.policy_padding{
    padding: 50px;
    
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .policy_wrapper{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        
        
    }
    
    .policy_div{
        margin-top: 80px;
        margin-bottom: 50px;
        background: #ffffff;
        border-radius: 5px;
        width: 95%;
       
        
    }
    
    .policy_padding{
        padding: 25px;
        
        
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .policy_wrapper{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        
    }
    
    .policy_div{
        margin-top: 80px;
        margin-bottom: 50px;
        background: #ffffff;
        border-radius: 5px;
        width: 80%;
        
    }
    
    .policy_padding{
        padding: 25px;
        
    }

}
    

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .policy_wrapper{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        
    }
    
    .policy_div{
        margin-top: 80px;
        margin-bottom: 50px;
        background: #ffffff;
        border-radius: 5px;
        width: 80%;
        
    }
    
    .policy_padding{
        padding: 25px;
        
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .policy_wrapper{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        
    }
    
    .policy_div{
        margin-top: 80px;
        margin-bottom: 50px;
        background: #ffffff;
        border-radius: 5px;
        width: 80%;
        
    }
    
    .policy_padding{
        padding: 50px;
        
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .policy_wrapper{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        
    }
    
    .policy_div{
        margin-top: 80px;
        margin-bottom: 50px;
        background: #ffffff;
        border-radius: 5px;
        width: 50%;
        
    }
    
    .policy_padding{
        padding: 50px;
        
    }
}