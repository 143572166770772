.payment-wrapper{
    margin-left: auto;
    margin-right: auto;
    background: #0a1224;
    height: 100vh;
}

.payment-div{
    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
    background: #ffffff;
    border-radius: 5px;
}

.payment-padding{
    padding: 30px;
}

.box2,
.box3,
.box4,
.box5{
    display: flex;
    justify-content: space-between;
}

.order-summary{
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 10px;
}

.box2{
    padding-top: 10px;
    padding-bottom: 10px;
}

.box3{
    padding-bottom: 10px;
}

.box4{
    padding-top: 10px;
    padding-bottom: 10px;
}

.box5{
    padding-top: 10px;
    padding-bottom: 10px;
}



.subscription{
    color: #616161;
    font-size: 15px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .payment-wrapper{
        margin-left: auto;
        margin-right: auto;
        background: #0a1224;
        height: 100vh;
    }
    
    .payment-div{
        margin-top: 150px;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        background: #ffffff;
        border-radius: 5px;
    }
    
    .payment-padding{
        padding: 30px;
    }
    
    .box2,
    .box3,
    .box4,
    .box5{
        display: flex;
        justify-content: space-between;
    }
    
    .order-summary{
        font-weight: bold;
        font-size: 20px;
        padding-bottom: 10px;
    }
    
    .box2{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .box3{
        padding-bottom: 10px;
    }
    
    .box4{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .box5{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .subscription{
        color: #616161;
        font-size: 15px;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .payment-wrapper{
        margin-left: auto;
        margin-right: auto;
        background: #0a1224;
        height: 100vh;
    }
    
    .payment-div{
        margin-top: 150px;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        background: #ffffff;
        border-radius: 5px;
    }
    
    .payment-padding{
        padding: 30px;
    }
    
    .box2,
    .box3,
    .box4,
    .box5{
        display: flex;
        justify-content: space-between;
    }
    
    .order-summary{
        font-weight: bold;
        font-size: 20px;
        padding-bottom: 10px;
    }
    
    .box2{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .box3{
        padding-bottom: 10px;
    }
    
    .box4{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .box5{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .subscription{
        color: #616161;
        font-size: 15px;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .payment-wrapper{
        margin-left: auto;
        margin-right: auto;
        background: #0a1224;
        height: 100vh;
    }
    
    .payment-div{
        margin-top: 150px;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        background: #ffffff;
        border-radius: 5px;
    }
    
    .payment-padding{
        padding: 30px;
    }
    
    .box2,
    .box3,
    .box4,
    .box5{
        display: flex;
        justify-content: space-between;
    }
    
    .order-summary{
        font-weight: bold;
        font-size: 20px;
        padding-bottom: 10px;
    }
    
    .box2{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .box3{
        padding-bottom: 10px;
    }
    
    .box4{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .box5{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .subscription{
        color: #616161;
        font-size: 15px;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .payment-wrapper{
        margin-left: auto;
        margin-right: auto;
        background: #0a1224;
        height: 100vh;
    }
    
    .payment-div{
        margin-top: 150px;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
        background: #ffffff;
        border-radius: 5px;
    }
    
    .payment-padding{
        padding: 30px;
    }
    
    .box2,
    .box3,
    .box4,
    .box5{
        display: flex;
        justify-content: space-between;
    }
    
    .order-summary{
        font-weight: bold;
        font-size: 20px;
        padding-bottom: 10px;
    }
    
    .box2{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .box3{
        padding-bottom: 10px;
    }
    
    .box4{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .box5{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .subscription{
        color: #616161;
        font-size: 15px;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .payment-wrapper{
        margin-left: auto;
        margin-right: auto;
        background: #0a1224;
        height: 100vh;
    }
    
    .payment-div{
        margin-top: 150px;
        margin-left: auto;
        margin-right: auto;
        width: 30%;
        background: #ffffff;
        border-radius: 5px;
    }
    
    .payment-padding{
        padding: 30px;
    }
    
    .box2,
    .box3,
    .box4,
    .box5{
        display: flex;
        justify-content: space-between;
    }
    
    .order-summary{
        font-weight: bold;
        font-size: 20px;
        padding-bottom: 10px;
    }
    
    .box2{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .box3{
        padding-bottom: 10px;
    }
    
    .box4{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .box5{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .subscription{
        color: #616161;
        font-size: 15px;
    }

}



