/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .homepage-wrap {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: auto;
        background-color: #090a29;
        scroll-behavior: smooth;
      }
      
      /* Section 1 */
      .section_1{
        height: auto;
        overflow: hidden;
      }
    
      .Front-banner-text {
        padding-top: 70px;
        position: relative;
      }
    
      .Square-back {
        background-image: linear-gradient(to right, transparent, #001666);
        height: 30vw;
        width: 60vw;
        border-radius: 0px 30px 30px 0;
        position: absolute;
        z-index: 1;
        top: 100px;
        right: 200px;
        -ms-transform: rotate(20deg); /* IE 9 */
        transform: rotate(20deg);
      }
      
    
      .Front-banner-text h1 {
        color: #ffffff;
        font-size: 7.5vw;
        text-align: center;
        text-justify: inter-word;
        position: relative;
        z-index: 2;
        padding: 40px 50px;
      }
    
      
    
      .Front-banner-button-register {
        color: #ffffff;
        border: 2px solid #ffffff;
        border-radius: 5px;
        text-align: center;
        margin: 10px auto;
        width: 40%;
        font-size: 20px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    
      .Front-banner-button-seepricing {
        color: #ffffff;
        border-radius: 5px;
        text-align: center;
        margin: 10px auto;
        width: 60%;
        font-size: 20px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #6400e3;
      }
    
      .Front-banner-div{
        text-align: center;
      }
    
      .Front-banner-div-img{
        width: 95%;
      }
    
      /* Section2 */
    
      .section_2{
        height: auto;
        overflow: hidden;
      }
    
      .Front-banner-text-2 {
        padding-top: 0px;
        position: relative;
      }
    
      .Square-back-2 {
        background-image: linear-gradient(to left, transparent, #001666);
        height: 30vw;
        width: 60vw;
        border-radius: 30px 0px 0px 30px;
        position: absolute;
        z-index: 1;
        top: 100px;
        left: 200px;
        -ms-transform: rotate(-20deg); /* IE 9 */
        transform: rotate(-20deg);
      }
    
      .Front-banner-text-2 h1 {
        color: #ffffff;
        font-size: 8vw;
        position: relative;
        z-index: 2;
        text-align: center;
        text-justify: inter-word;
        padding: 40px 50px;
      }
    
    
      .Front-banner-div-2{
        text-align: center;
      }
    
      .Front-banner-div-img-2{
        width: 80%;
      }

      .Front-banner-div-2-duplicate {
        display: none;
      }
    
      
    
      /* Section3 */
    
      .section_3{
        height: auto;
        overflow: hidden;
      }
    
      .Front-banner-text-3 {
        padding-top: 0px;
        position: relative;
      }
    
      .Square-back-3 {
        background-image: linear-gradient(to right, transparent, #001666);
        height: 30vw;
        width: 60vw;
        border-radius: 0px 30px 30px 0;
        position: absolute;
        z-index: 1;
        top: 50px;
        right: 200px;
        -ms-transform: rotate(20deg); /* IE 9 */
        transform: rotate(20deg);
      }
    
      .Front-banner-text-3 h1 {
        color: #ffffff;
        font-size: 8vw;
        text-align: center;
        text-justify: inter-word;
        position: relative;
        z-index: 2;
        padding: 40px 50px;
        overflow: hidden;
      }
    
      .Front-banner-div-3{
        text-align: center;
      }
    
      .Front-banner-div-img-3{
        width: 95%;
      }
    
      /* Pricing */
      .membershipwrapper {
        margin-left: auto;
        margin-right: auto;
        background: #0a1224;
        text-align: center;
      }
    
      .circle {
        height: 50px;
        width: 50px;
        background-image: linear-gradient(to right, transparent, #001666);
        border-radius: 50%;
        -ms-transform: rotate(20deg); /* IE 9 */
        transform: rotate(20deg);
      }
    
      .pricing {
        color: #ffffff;
        margin-top: 20px;
        font-size: 8vw;
      }
    
      .pricing-text {
        color: #ffffff;
        font-size: 4vw;
      }
    
      .pricing-padding {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
      }
    
      .membershipdiv1 {
        background: #6400E3;
        border-radius: 5px;
        width: 100%;
      }
    
      .memberpadding {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-bottom: 20px;
      }
    
      .free,
      .price-dollar {
        font-size: 30px;
        color: #ffffff;
        margin: 15px 10px 15px 10px;
      }
    
      .details1 {
        margin-left: auto;
        margin-right: auto;
      }
    
      .plandetails {
        color: #ffffff;
        margin: 0px 10px 0px 10px;
    
      }
    
      .price-button1 {
        background: #ffffff;
        padding: 5px 30px;
        border-radius: 5px 15px;
        color: #011766;
        margin-top: 50px;
      }
    
      .membershipdiv2 {
        background: #011766;
        border-radius: 10px;
        width: 100%;
        margin-top: 40px;
      }
    
      .monthly,
      .price-dollar2 {
        font-size: 30px;
        color: #ffffff;
        margin: 15px 10px 15px 10px;
      }
    
      .details2 {
        margin-left: auto;
        margin-right: auto;
      }
    
      .plandetails2 {
        color: #ffffff;
        margin: 0px 10px 0px 10px;
    
      }
    
      .price-button2 {
        background: #ffffff;
        padding: 5px 30px;
        border-radius: 5px 15px;
        color: #30002d;
        margin-top: 50px;
      }
    
    
    
      /* Contact */
    
      #contact-form {
        margin: 50px 15vw;
      }
    
    
      .contact-us {
        color: #ffffff;
      }
    
      .form-group1 {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
      }
    
      .form-control1 {
        background-color: #ffffff;
        border-radius: 5px;
        height: 40px;
        width: 100%;
        margin-bottom: 15px;
      }
    
      .form-control1:focus {
        background-color: #d8d8d8;
        color: #ffffff;
        border-radius: 5px;
        outline: none;
        height: 40px;
        width: 100%;
        margin-bottom: 15px;
      }
    
      .form-group2 {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
      }
    
      .privacy_div {
        display: flex;
        align-items: center;
      }
    
      .privacy_text {
        color: #ffffff;
        font-size: 15px;
      }
    
      .privacy_link {
        color: #0038b9;
      }
    
      .privacy_link:hover {
        text-decoration: underline;
      }
    
      .form-control2 {
        background-color: #ffffff;
        border-radius: 5px;
        width: 100%;
        height: 40px;
        margin-bottom: 15px;
        
      }
    
      .form-control2:focus, .form-control2-message:focus{
        outline: none;
        background-color: #d8d8d8;
        color: #ffffff;
      }
    
      .form-control2-message{
        background-color: #ffffff;
        border-radius: 5px;
        width: 100%;
        height: 100px;
        margin-bottom: 15px;
      }
    
    
      .btn-submit{
        width: 50%;
        border-radius: 5px 20px 5px 20px;
      }




}