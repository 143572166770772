.home-section {
  position: relative;
  background: #03060d;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
  
}

.Dashboard {
  background-color: #0c1325;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  
}

.home-section-padding {
  padding-top: 60px;
  
}

.home-body1,.home-body2,
.home-body3,.home-body4,
.home-body5 {
  padding: 0px 30px 0px 30px;
}

.Dashboard h1 {
  font-size: 30px;
  color: #ffffff;
  margin-left: 25px;
}

.additems-btn {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px 5px 0px;
  color: #ffffff;
  align-items: center;
  
}

.btn-editor-toggle {
  display: flex;
  align-items: center;
  background-color: #300158;
  padding: 5px 15px;
  border-radius: 5px;
  overflow: hidden;
}

.home-hr1 {
  border: 2px solid #3f0071;
  
}

.snippet-div-1 {
  display: flex;
  overflow-x: scroll;
}

.snippet-div-1::-webkit-scrollbar {
  height: 10px;
  background-color: transparent;
}

.snippet-div-1::-webkit-scrollbar-thumb {
  background: #300158;


}

.no-item-div{
  background-color: #0A1121;
  height: 180px;
  border-radius: 15px;
  text-align: center;
  display: block;
  justify-content: center;
  margin-left: 10px;
}

.no-snippets-msg{
  color: #ffffff;
  margin: 30px;
  font-size: 15px;
}

.no-items-div-1{
  
  padding-left: 15%;
  padding-right: 15%;
}

.noitems-div1{
  height: 10px;
  background: #111d38;
  padding: 0px 10px;  
  margin-top: 5px;
}

#log_out{
  color: rgb(160, 0, 0);
  
}