.terms{
    display: flex;
    align-items: center;
    
}

.terms-conditions{
    color: rgb(11, 83, 179);
}

.terms-conditions:hover{
    text-decoration: underline;
}

.terms-div{
    margin-top:10px
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .registerwrapper{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #0a1224;
        height: 100vh;
    }

    .register-auth-form{
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
        background: #ffffff;
        border-radius: 5px;
        padding: 25px 30px;
        height: 450px;
        width: 100%;
        text-align: center;
    }

    .registerlabel{
        color: #0a1224;
        padding-bottom: 20px;
    }

    #register-form-email,
    #register-form-password,
    #register-form-passwordVerify{
        background: #ececec;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        color: rgb(0, 0, 0);
    }

    #register-form-email,
    #register-form-password{
        margin-bottom: 15px;
    }

    #register-form-email:focus,
    #register-form-password:focus,
    #register-form-passwordVerify:focus{
        background: #0a1224;
        border: 2px solid #0a1224;
        color: #ffffff;
    }

    .logindiv{
        text-align: left;
    }

    .loginlink{
        color: #0041da;
    }

    .textlogin{
        font-size:  2.8vw;
    }

    .regbutton{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        align-items: center;
        padding-top: 10px;
    }

    .btn-submit{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #640870;
        border-radius: 5px;
        height: 40px;
        color: #ffffff;
    }


}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .registerwrapper{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #0a1224;
        height: 100vh;
    }

    .register-auth-form{
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
        background: #ffffff;
        border-radius: 5px;
        padding: 25px 30px;
        height: 450px;
        width: 80%;
        text-align: center;
    }

    .registerlabel{
        color: #0a1224;
        padding-bottom: 20px;
    }

    #register-form-email,
    #register-form-password,
    #register-form-passwordVerify{
        background: #ececec;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        color: rgb(0, 0, 0);
    }

    #register-form-email,
    #register-form-password{
        margin-bottom: 15px;
    }

    #register-form-email:focus,
    #register-form-password:focus,
    #register-form-passwordVerify:focus{
        background: #0a1224;
        border: 2px solid #0a1224;
        color: #ffffff;
    }

    .logindiv{
        text-align: left;
    }

    .loginlink{
        color: #0041da;
    }

    .textlogin{
        font-size:  2.5vw;
    }

    .regbutton{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        align-items: center;
        padding-top: 10px;
    }

    .btn-submit{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #640870;
        border-radius: 5px;
        height: 40px;
        color: #ffffff;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .registerwrapper{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #0a1224;
        height: 100vh;
    }

    .register-auth-form{
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
        background: #ffffff;
        border-radius: 5px;
        padding: 25px 30px;
        height: 450px;
        width: 60%;
        text-align: center;
    }

    .registerlabel{
        color: #0a1224;
        padding-bottom: 20px;
    }

    #register-form-email,
    #register-form-password,
    #register-form-passwordVerify{
        background: #ececec;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        color: rgb(0, 0, 0);
    }

    #register-form-email,
    #register-form-password{
        margin-bottom: 15px;
    }

    #register-form-email:focus,
    #register-form-password:focus,
    #register-form-passwordVerify:focus{
        background: #0a1224;
        border: 2px solid #0a1224;
        color: #ffffff;
    }

    .logindiv{
        text-align: left;
    }

    .loginlink{
        color: #0041da;
    }

    .textlogin{
        font-size:  1.5vw;
    }

    .regbutton{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        align-items: center;
        padding-top: 10px;
    }

    .btn-submit{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #640870;
        border-radius: 5px;
        height: 40px;
        color: #ffffff;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .registerwrapper{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #0a1224;
        height: 100vh;
    }

    .register-auth-form{
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
        background: #ffffff;
        border-radius: 5px;
        padding: 25px 30px;
        height: 450px;
        width: 50%;
        text-align: center;
    }

    .registerlabel{
        color: #0a1224;
        padding-bottom: 20px;
    }

    #register-form-email,
    #register-form-password,
    #register-form-passwordVerify{
        background: #ececec;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        color: rgb(0, 0, 0);
    }

    #register-form-email,
    #register-form-password{
        margin-bottom: 15px;
    }

    #register-form-email:focus,
    #register-form-password:focus,
    #register-form-passwordVerify:focus{
        background: #0a1224;
        border: 2px solid #0a1224;
        color: #ffffff;
    }

    .logindiv{
        text-align: left;
    }

    .loginlink{
        color: #0041da;
    }

    .textlogin{
        font-size:  1.3vw;
    }

    .regbutton{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        align-items: center;
        padding-top: 10px;
    }

    .btn-submit{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #640870;
        border-radius: 5px;
        height: 40px;
        color: #ffffff;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .registerwrapper{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #0a1224;
        height: 100vh;
    }

    .register-auth-form{
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
        background: #ffffff;
        border-radius: 5px;
        padding: 25px 30px;
        height: 450px;
        width: 30%;
        text-align: center;
    }

    .registerlabel{
        color: #0a1224;
        padding-bottom: 20px;
    }

    #register-form-email,
    #register-form-password,
    #register-form-passwordVerify{
        background: #ececec;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        color: rgb(0, 0, 0);
    }

    #register-form-email,
    #register-form-password{
        margin-bottom: 15px;
    }

    #register-form-email:focus,
    #register-form-password:focus,
    #register-form-passwordVerify:focus{
        background: #0a1224;
        border: 2px solid #0a1224;
        color: #ffffff;
    }

    .logindiv{
        text-align: left;
    }

    .loginlink{
        color: #0041da;
    }

    .textlogin{
        font-size:  .8vw;
    }

    .regbutton{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        align-items: center;
        padding-top: 10px;
    }

    .btn-submit{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #640870;
        border-radius: 5px;
        height: 40px;
        color: #ffffff;
    }
}