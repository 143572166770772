/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 1200px) {
    .homepage-wrap {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: auto;
      background-color: #090a29;
      scroll-behavior: smooth;
      
    }
  
  
    /* Section 1 */
    .section_1 {
      height: 100vh;
      overflow: hidden;
      display: flex;
      padding-top: 150px;
    }
  
    .section-1-padding{
      display: flex;
      align-items: center;
      padding: 0px 150px;
    }

    .Front-banner-text {
      padding-top: 0px;
      position: relative;
      overflow: visible;
      
    }
  
    .Square-back {
      background-image: linear-gradient(to right, transparent, #001666);
      height: 300px;
      width: 600px;
      border-radius: 0px 30px 30px 0;
      position: absolute;
      z-index: 1;
      top: -50px;
      right: 140px;
      -ms-transform: rotate(20deg); /* IE 9 */
      transform: rotate(20deg);
    }
  
    .Front-banner-button {
      display: flex;
      justify-content: left;
      padding-left: 80px;
    }
  
    .Front-banner-text h1 {
      color: #ffffff;
      font-size: 35px;
      text-align: left;
      text-justify: inter-word;
      position: relative;
      z-index: 2;
      padding: 40px 80px;
    }
  
    .Front-banner-button-register {
      color: #ffffff;
      border: 2px solid #ffffff;
      border-radius: 5px 15px;
      text-align: center;
      margin: 10px 5px;
      width: 120px;
      font-size: 15px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;
    }

    .Front-banner-button-register:hover{
      border: 2px solid #6400e3;

    }
  
    .Front-banner-button-seepricing {
      color: #ffffff;
      border-radius: 5px 15px;
      text-align: center;
      margin: 10px 5px;
      width: 200px;
      font-size: 15px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #6400e3;
      position: relative;
      z-index: 2;
    }

    .Front-banner-button-seepricing:hover{
      background-color: #4f00b6;
    }
  
    .Front-banner-div {
      text-align: center;
      padding-top: 0px;
      overflow: hidden;
    }
  
    .Front-banner-div-img {
      width: 100%;
      padding: 20px 0px;
    }
  
    /* Section2 */
  
    .section_2 {
      height: 100vh;
      overflow: hidden;
      display: flex;
      

    }

    .section-2-padding{
      display: flex;
      align-items: center;
      padding: 0px 150px;
      
    }
  
    .Front-banner-text-2 {
      padding-top: 0px;
      position: relative;
      overflow: visible;
      height:400px;
      display: flex;
      align-items: center;
    }
  
    .Square-back-2 {
      background-image: linear-gradient(to left, transparent, #001666);
      height: 300px;
      width: 600px;
      border-radius: 30px 0px 0px 30px;
      position: absolute;
      z-index: 1;
      top: 0px;
      left: 250px;
      -ms-transform: rotate(-20deg); /* IE 9 */
      transform: rotate(-20deg);
    }
  
    .Front-banner-text-2 h1 {
      color: #ffffff;
      font-size: 35px;
      text-align: right;
      text-justify: inter-word;
      position: relative;
      z-index: 2;
      padding: 40px 50px;
    }
  
    .Front-banner-div-2 {
      display:none;
    }
  
    .Front-banner-div-img-2 {
      display:none;
    }
  
    .Front-banner-div-2-duplicate {
      text-align: center;
      padding-top: 0px;
      display: inline-block;
    }
  
    .Front-banner-div-img-2-duplicate {
      width: 100%;
      padding: 20px 0px;
      
    }
  
    /* Section3 */
  
    .section_3 {
      height: 100vh;
      overflow: hidden;
      display: flex;
    
    }
  
    .section-3-padding{
      display: flex;
      align-items: center;
      padding: 0px 150px;
    }

    .Front-banner-text-3 {
      padding-top: 0px;
      position: relative;
      overflow: visible;
      
    }
  
    .Square-back-3 {
      background-image: linear-gradient(to right, transparent, #001666);
      height: 300px;
      width: 600px;
      border-radius: 0px 30px 30px 0;
      position: absolute;
      z-index: 1;
      top: -50px;
      right: 140px;
      -ms-transform: rotate(20deg); /* IE 9 */
      transform: rotate(20deg);
    }
  

    .Front-banner-text-3 h1 {
      color: #ffffff;
      font-size: 35px;
      text-align: left;
      text-justify: inter-word;
      position: relative;
      z-index: 2;
      padding: 40px 80px;
    }
  

  
    .Front-banner-div-3 {
      text-align: center;
      padding-top: 0px;
      overflow: hidden;
    }
  
    .Front-banner-div-img-3 {
      width: 100%;
      padding: 20px 0px;
    }
  
    /* Pricing */
    .membershipwrapper {
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      background: #0a1224;
      text-align: center;
    }
  
    .pricing {
      color: #ffffff;
      margin-top: 20px;
      font-size: 30px;
    }
  
    .pricing-text {
      color: #ffffff;
      font-size: 20px;
    }
  
    .pricing-padding {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 40px;
      display:flex;
    }
  
    .membershipdiv1 {
      background: #6400e3;
      border-radius: 5px;
      width: 100%;
    }
  
    .memberpadding {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      margin-bottom: 20px;
    }
  
    .free,
    .price-dollar {
      font-size: 30px;
      color: #ffffff;
      margin: 15px 10px 15px 10px;
    }
  
    .details1 {
      margin-left: auto;
      margin-right: auto;
    }
  
    .plandetails {
      color: #ffffff;
      margin: 0px 10px 0px 10px;
    }
  
    .price-button1 {
      background: #ffffff;
      padding: 5px 30px;
      border-radius: 5px 15px;
      color: #011766;
      margin-top: 50px;
    }
  
    .membershipdiv2 {
      background: #011766;
      border-radius: 10px;
      width: 100%;
      margin-top: 0px;
    }
  
    .monthly,
    .price-dollar2 {
      font-size: 30px;
      color: #ffffff;
      margin: 15px 10px 15px 10px;
    }
  
    .details2 {
      margin-left: auto;
      margin-right: auto;
    }
  
    .plandetails2 {
      color: #ffffff;
      margin: 0px 10px 0px 10px;
    }
  
    .price-button2 {
      background: #ffffff;
      padding: 5px 30px;
      border-radius: 5px 15px;
      color: #30002d;
      margin-top: 50px;
    }
  
    /* Contact */
  
    #contact-form {
      margin: 50px 30vw;
    }
  
    .contact-us {
      color: #ffffff;
    }
  
    .form-group1 {
      margin-left: auto;
      margin-right: auto;
      width: 90%;
    }
  
    .form-control1 {
      background-color: #ffffff;
      border-radius: 5px;
      height: 30px;
      width: 100%;
      margin-bottom: 10px;
    }
  
    .form-control1:focus {
      background-color: #d8d8d8;
      color: #ffffff;
      border-radius: 5px;
      outline: none;
      height: 40px;
      width: 100%;
      margin-bottom: 15px;
    }
  
    .form-group2 {
      margin-left: auto;
      margin-right: auto;
      width: 90%;
    }
  
    .privacy_div {
      display: flex;
      align-items: center;
    }
  
    .privacy_text {
      color: #ffffff;
      font-size: 15px;
    }
  
    .privacy_link {
      color: #0038b9;
    }
  
    .privacy_link:hover {
      text-decoration: underline;
    }
  
    .form-control2 {
      background-color: #ffffff;
      border-radius: 5px;
      width: 100%;
      height: 30px;
      margin-bottom: 10px;
    }
  
    .form-control2:focus,
    .form-control2-message:focus {
      outline: none;
      background-color: #d8d8d8;
      color: #ffffff;
    }
  
    .form-control2-message {
      background-color: #ffffff;
      border-radius: 5px;
      width: 100%;
      height: 100px;
      margin-bottom: 15px;
    }
  
    .btn-submit {
      width: 200px;
      border-radius: 5px 20px 5px 20px;
    }
  }
  